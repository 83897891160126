import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { IMAGE_BASE_URL } from "../../Redux/Constants/GlobalConstant";
import QuantityInput from "../QuantityInput";

const ShopSection = (props) => {
  const { keyword, pagenumber } = props;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  useEffect(() => {
    dispatch(listProduct(keyword, pagenumber));
  }, [dispatch, keyword, pagenumber]);
  return (
    <>
      <div className="container">
        <div className="section">
          <div className="row mobile-thumbnail">
            <div className="col-lg-12 col-md-12 article">
              {/* <h5></h5> */}

              <div className="shopcontainer row">
                {loading ? (
                  <div className="mb-5">
                    <Loading />
                  </div>
                ) : error ? (
                  <Message variant="alert-danger">{error}</Message>
                ) : (
                  <>
                    {products.map((product) => (
                      <div
                        className="shop col-lg-2 col-md-2 col-sm-2"
                        key={product._id}
                      >
                        <div className="border-product">
                          <Link to={`/products/${product._id}`}>
                            <div className="shopBack">
                              {product.mrp_price - product.price > 0 && (
                                <div>
                                  <div className="offer_strip">
                                    {Math.floor(
                                      ((product.mrp_price - product.price) /
                                        product.price) *
                                        100
                                    )}{" "}
                                    %
                                  </div>
                                </div>
                              )}
                              <img
                                src={
                                  IMAGE_BASE_URL +
                                  "/" +
                                  // product._id+"_1.jpg"
                                  product.image
                                }
                                alt={product.name}
                              />
                            </div>
                          </Link>

                          <div className="shoptext">
                            <p>
                              <Link to={`/products/${product._id}`}>
                                {product.name} - {product?.unit}
                              </Link>
                            </p>

                            {/* <Rating
                              value={product.rating}
                              text={`${product.numReviews} reviews`}
                            /> */}
                            <div className="price-button-thumb-sec">
                              <h3>
                                ₹ {product.price}
                                {product.mrp_price - product.price > 0 && (
                                  <span className="text-overline">
                                    ₹ {product.mrp_price}
                                  </span>
                                )}
                                {product.mrp_price - product.price < 0 && (
                                  <span className="text-overline"> </span>
                                )}
                              </h3>
                              <QuantityInput
                                productId={product._id}
                                renderAddButton={true}
                                qty={0}
                                max_order={product?.max_order}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* Pagination */}
              </div>
              {/* <div className="pagination-nav-bar">
                <Pagination
                  pages={pages}
                  page={page}
                  keyword={keyword ? keyword : ""}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopSection;
