import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listGroup } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { Link } from "react-router-dom";

const CategorySection = (props) => {
  // const { keyword, pagenumber } = props;
  const dispatch = useDispatch();

  const groupList = useSelector((state) => state.groupList);
  const { loading, error, groups } = groupList;

  useEffect(() => {
    dispatch(listGroup());
  }, [dispatch]);

  return (
       <div className="container">
        {/* Product Category */}
        {loading ? (
          <div className="mb-5">
            <Loading />
          </div>
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <div className="section">
            <h5>Shop by Category</h5>
            <div className="scrollmenu">
              {groups?.map((group, i) => (
                <Link to={`/group/${group._id}`} key={i}>
                   
                  <div className="cat-section-thumb-img">
                    <img
                      src={
                        "https://agrocroft-ecom-bucket.s3.ap-south-1.amazonaws.com/ag_group_icon/" +
                        group.group_img
                      }
                      alt={group?.group_img}
                    />
                    <span>{group.group_name}</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
   );
};

export default CategorySection;
