import axios from "axios";
import {
  BEST_DEAL_PRODUCT_LIST_FAIL,
  BEST_DEAL_PRODUCT_LIST_REQUEST,
  BEST_DEAL_PRODUCT_LIST_SUCCESS,
  CAT_PRODUCT_LIST,
  GROUP_CATEGORY_FAIL,
  GROUP_CATEGORY_REQUEST,
  GROUP_CATEGORY_SUCCESS,
  GROUP_LIST_FAIL,
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_PRODUCT_FAIL,
  GROUP_PRODUCT_REQUEST,
  GROUP_PRODUCT_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
} from "../Constants/ProductConstants";
import { BASE_URL } from "../Constants/GlobalConstant";

import { logout } from "./userActions";

// PRODUCT LIST
export const listProduct =
  (keyword = " ", pageNumber = " ") =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const { data } = await axios.get(
        BASE_URL + `/api/products?keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// / BEST DEAL PRODUCT LIST
export const bestDealProductList = () => async (dispatch) => {
  try {
    dispatch({ type: BEST_DEAL_PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(BASE_URL + `/api/web/product/best-deal`);
    dispatch({ type: BEST_DEAL_PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BEST_DEAL_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SINGLE PRODUCT
export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(BASE_URL + `/api/products/${id}`);
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// SINGLE GROUP IN Detail
export const listProductsGroupWise = (id) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_PRODUCT_REQUEST });
    const { data } = await axios.get(BASE_URL + `/api/web/group/product/${id}`);
    dispatch({ type: GROUP_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GROUP_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Group Category List Based on Group ID
export const listCategroyGroupWise = (id) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_CATEGORY_REQUEST });
    const { data } = await axios.get(
      BASE_URL + `/api/web/group/category/${id}`
    );
    dispatch({ type: GROUP_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GROUP_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// PRODUCT REVIEW CREATE
export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.post(
        BASE_URL + `/api/products/${productId}/review`,
        review,
        config
      );
      dispatch({ type: PRODUCT_CREATE_REVIEW_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload: message,
      });
    }
  };

// PRODUCT LIST
export const listGroup =
  (keyword = " ", pageNumber = " ") =>
  async (dispatch) => {
    try {
      dispatch({ type: GROUP_LIST_REQUEST });
      const { data } = await axios.get(
        BASE_URL + `/api/group?keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: GROUP_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GROUP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// SINGLE GROUP IN Detail
export const categoryProductList = (id) => async (dispatch) => {
  try {
    dispatch({ type: CAT_PRODUCT_LIST });
    const { data } = await axios.get(BASE_URL + `/api/category/${id}/product`);
    dispatch({ type: CAT_PRODUCT_LIST, payload: data });
  } catch (error) {
    dispatch({
      type: CAT_PRODUCT_LIST,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
