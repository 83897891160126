import React, { useState } from "react";

const MessageModal = ({ show, msg }) => {
  const [showModel, setShowModel] = useState(show);
  const showHideClassName = showModel
    ? "modal display-block"
    : "modal display-none";

  const handleClose = () => {
    setShowModel(false);
  };
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="Login">
          {msg}
          <button type="button" onClick={handleClose}>
            Done
          </button>
        </div>
      </section>
    </div>
  );
};

export default MessageModal;
